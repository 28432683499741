.gallery-item img {
  max-height: 40vh;
  width: 100%;
  object-fit: contain;
  margin: auto;
}

.custom-dots {
  margin-top: 20px;
}

.gallery-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  overflow: hidden;
}
