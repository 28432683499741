html, body {
    height: 100%;
    margin: 0;
  }
  
  #root { /* Assuming #root is your main wrapper */
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  
  .footer {
    margin-top: auto; /* This pushes the footer to the bottom */
  }
  